import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Config from '../../../helpers/Config';
import SiteBreadcrumb from '../../components/layout/SiteBreadcrumb';
import BestFacilities from '../../components/shared/BestFacilities';
import about1 from '../../resources/themeContent/images/theme-images/about1.jpg';
import about2 from '../../resources/themeContent/images/theme-images/about2.jpg';
import signature from '../../resources/themeContent/images/theme-images/signature.png';


const ReturnandRefund = () => {
  const [siteTitle, setSiteTitle] = useState(Config['SITE_TTILE']);

  return (
    <>


      <Helmet>
        <title>{siteTitle} - Return & Refund</title>
        <meta name="description" content={siteTitle + " - Return & Refund page"}  />
        <meta name="keywords" content="Return & Refund"></meta>
      </Helmet>

      <SiteBreadcrumb title="Return & Refund" />

      <section className="about-area ptb-60">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="about-content">
                <h2>Our Return & Refund</h2>
                <p>Fashion is like dreaming with your feet!</p>

                <p>We are committed to make your shopping experience hassle-free and enjoyable as much as possible! Please read through the following terms & conditions prior making a purchase. We are dedicated at www.buddha4all.com to offer you the best quality products and services.</p>

                <p>Making a purchase indicates that you have acknowledged, understood, and agree to adhere to the following terms and conditions.</p>

                <p>As a company policy, products can be returned within 7 days from the date of delivery. Payment Refunds are applicable only in case of manufacturing defects or delivery of incorrect size (size other than that ordered). In all other cases, a Credit note (90 Days validity) is available.</p>

                <p>Exchange can be done for a bigger or a smaller size (provided the aforementioned terms and conditions are met). The customer will be issued a credit note, in case the desired size of the product is not in stock.</p>

                <p>Returns and Replacements are to be done by the brand if wrong products (incomplete sets or incorrect style), wrong sizes (sizes other than that ordered), or manufacturing defects are received by the customer. However, the Company reserves the right to levy shipping charges on cases under legal scrutiny.</p>
                
                <p>We recommend making a video while opening the parcel you’ve received to avoid any conflicts while applying for returns.</p>
                
                <p>We have named it our Returns and Exchange policy which is as follows : </p>
                
                 <ul>
                            <li>Return Intimation: </li>  
                              <p>You can either call our Customer Support +0120- 4483203 or Email Us at info@buddha4all.in within 7 days from the date of delivery. The details of the return (Order number, courier docket No or AWB No, and Reason for Return) should be shared with Customer Support by email.
You can apply for a Return within 7 Days from the date of delivery & a credit note will be issued (Shipping/COD charges if applicable will not be refunded).
You may contact our Customer Support at +0120- 4483203 or email at info@buddha4all.in (Support is available from Monday to Saturday, 10 AM to 7 PM)
Once Return/ Exchange request is acknowledged by the buddha4all team during the next working day, please send the product back to us, either by yourself or if the brand needs to pick up the parcel then ₹200 will be charged to the customer. </p>
                           
<p>Please do not forget to make a video while packing your returned items & take an acknowledgment receipt from the courier person about the number of products you are dispatching with his signature and mobile number. buddha4all will not be responsible for any lost shipment.</p>

 <p>The cost of return shipping is at the customer’s expense and is non-refundable. We recommend returning items through traceable mail.</p>                          
                         
                            <li> Address for the Exchange: </li>
                            <p>I Thum Buelding. Tower B 4th Floor office Number 422 B Sector 62 Noida U.P. 201301
The credit note will be initiated within 48 hours, once we get the parcel back with us and the quality check is successful. Returned products must be unused, unworn, unwashed, and undamaged, with all labels and tags completely intact, and in original packaging acceptable.</p>      

<p>15 days Exchange window is open from the date of delivery.</p>     


<p>Only unused, unworn, unwashed, undamaged products, with all labels and tags completely intact, and in original packaging are eligible for exchange.
Only Size exchange is available (the customer is eligible to get a bigger or smaller size, equal to the amount of the exchanged product minus the shipping/COD cost of to & fro).
In case the size you have requested is not available we will offer you the option to get a credit note to buy something else.
Please mention your name, address, number, email, and order id on the parcel along with the size needed.
Please note, that exchange is allowed once per order.
Store credits equalling the amount of the exchanged product minus the shipping/COD cost of to & fro with a validity of 90 days from the date of a store credit receipt.
The cost of shipping to & fro (reverse pickup as well as shipping charges) for the product is to be borne by the customer.
</p>   

<p>Note :Special Sale products are neither Returnable nor Exchangeable. For raising any disputes, a package opening video is required for the investigation.</p>   


                            <li>Can I cancel my order?</li>
                            <p>Orders can be canceled within 24 hours once placed.</p>   

                            <p>Please call us at +0120- 4483203 or write to us at info@buddha4all.in to request a cancellation.</p>   

                            <p>The customer agrees not to dispute the decision made by buddha4all and accepts the brand’s decision regarding the cancellation.</p>   

                            <li> How will I receive the credit for my money when the desired size of the exchange product is not available?</li>
                            <p>A credit note will be Emailed or WhatsApp to you, which you can use in the next 90 days on the buddha4all website. Please make sure that you use the credit in one go.</p>   
                    

                            <p>Note:
Our products are made and handled by human hands involving various processes. There are bound to be variations in color, finish and overall look. The colors you see on our website will depend on the accuracy of your screen for which subtle variations must be acknowledged.
10-12% Colour difference depends on the screen resolution of the device used by the client and camera lights, please don’t expect an exchange or return for the same.</p>   

<p>Return is available only for domestic orders.</p>   

<p>Refunds or exchanges will be processed after receiving the returned items.</p>  
                    


                        </ul>   

                      
                
                <div className="signature mb-0">
                  <img src={signature} alt="image" />
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="about-image">
                <img src={about1} className="about-img1" alt="image" />
                <img src={about2} className="about-img2" alt="image" />
              </div>
            </div>
          </div>
        </div>
      </section>



      <BestFacilities />

    </>
  );

}

export default ReturnandRefund;
