import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Config from '../../../helpers/Config';
import SiteBreadcrumb from '../../components/layout/SiteBreadcrumb';
import BestFacilities from '../../components/shared/BestFacilities';
import about1 from '../../resources/themeContent/images/theme-images/about1.jpg';
import about2 from '../../resources/themeContent/images/theme-images/about2.jpg';
import signature from '../../resources/themeContent/images/theme-images/signature.png';


const Privacy = () => {
  const [siteTitle, setSiteTitle] = useState(Config['SITE_TTILE']);

  return (
    <>


      <Helmet>
        <title>{siteTitle} - Privacy and Policy</title>
        <meta name="description" content={siteTitle + " - Privacy and Policy page"}  />
        <meta name="keywords" content="Privacy and Policy"></meta>
      </Helmet>

      <SiteBreadcrumb title="Privacy and Policy" />

      <section className="about-area ptb-60">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="about-content">
                <h2>Our Privacy and Policy</h2>
                <p>Fashion is like dreaming with your feet!</p>

                <p>We respect your privacy and are committed to maintaining it. When you shop on this buddha4all Website, we will ask you to input and will collect Personal Information from you such as your name, e-mail address, billing address, delivery address, telephone number, product selections, credit card or other payment information, and password.</p>

                <p>We may also collect information about where you are on the internet (eg the URL you came from, IP address, domain types like .co.uk and .com), your browser type, the country where your computer is located, the pages of our website that were viewed during your visit, the advertisements you clicked on. We may collect this information even if you do not register with us. None of this data is of a personal nature and will help us improve the quality of our service.</p>

                 <ul>
                            <li>How we use the information we collect</li>
                            <li> Processing your orders</li>
                            <li>Administering your account</li>
                            <li> Improving the website’s performance by analyzing statistical data</li>
                        </ul>   

                        <p>If you consent, to notify you of products or special offers that may be of interest to you. You agree that you do not object to us contacting you for any of the above purposes whether, by telephone, e-mail, or in writing and you confirm that you do not and will not consider any of the above as being a breach of any of your rights.</p>

                        <p>We will not release your Personal Information to any company outside for mailing or marketing purposes.</p>

                        <p>We do not hold any of your credit card information. This information is securely collected by our authorized payment processor. With the industry’s most advanced encryption, fraud prevention, and protection policies, Secure Trading is dedicated to keeping your account safe and secure. Once your information reaches Secure Trading, it resides on a server that is heavily guarded both physically and electronically. Secure Trading servers sit behind an electronic firewall and are not directly connected to the Internet, so your private information is available only to authorized computers. Please check back frequently to see any updates or changes to our privacy policy.</p>
                
                <div className="signature mb-0">
                  <img src={signature} alt="image" />
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="about-image">
                <img src={about1} className="about-img1" alt="image" />
                <img src={about2} className="about-img2" alt="image" />
              </div>
            </div>
          </div>
        </div>
      </section>



      <BestFacilities />

    </>
  );

}

export default Privacy;
